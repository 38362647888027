import { render, staticRenderFns } from "./Antonina.vue?vue&type=template&id=2e593fd4&scoped=true&"
import script from "./Antonina.vue?vue&type=script&lang=js&"
export * from "./Antonina.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e593fd4",
  null
  
)

export default component.exports